import * as types from '@/redux/contains/types';

const initialState = {
    isLoading:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RUN_REFRESH_TOKEN:
            return {
                isLoading:true
            };
        case types.STOP_REFESH_TOKEN:
            return {
                isLoading:false
            }
        default:
            return state;
    }
}

export default reducer;