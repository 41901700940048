import * as types from '@/redux/contains/types';

const initialState = {
    windowSize: null,
    message: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.WINDOWSIZE_INIT:
            return {
                windowSize: null,
                message: ""
            };
        case types.WINDOWSIZE_CHANGE:
            return {
                windowSize: action.windowSize,
                message: ""
            }
        default:
            return state;
    }
}

export default reducer;