import * as types from '@/redux/contains/types';

const initialState = {
    detail: null,
    companys: [],
    totalproject: null,
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_COMPANY_DETAIL_INI:
            return {
                ...state
            };
        case types.USER_COMPANY_DETAIL_SUCCESS:
            return {
                ...state,
                detail: action.detail,
                companys: action.companys
            }
        case types.USER_COMPANY_PROJECT_SUCCESS:
            return {
                ...state,
                totalproject: action.totalproject,
            }
        case types.USER_COMPANY_DETAIL_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;