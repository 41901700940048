/*login*/
export const AUTH_INI = 'ZEwigjGHSE';
export const AUTH_SUCCESS = 'KUg7y4NM2g';
export const AUTH_LOADING = '055B317ZU6';
export const AUTH_RELOAD_TOKEN = '09TB317ZU6';
/*error*/
export const ERROR_INI = 'jEbo2DdH4Z';
export const ERROR_SHOW = 'uujsKl9P1W';

/*ROLE COMPANY
*   1. List role
*/
export const ROLE_COMPANY_INI = 'w8eP2d8Kg2';
export const ROLE_COMPANY_SUCCESS = 'XRFEloji9u';
export const ROLE_COMPANY_LOADING = 'xZZrDW0IYI';
export const ROLE_COMPANY_DETAIL = 'oN8DhC8Oeg';
/*
*   2. List config
*/
export const CONFIG_ROLE_COMPANY_INI = 'nW8BFrqJo0';
export const CONFIG_ROLE_COMPANY_SUCCESS = 'tMxUtFlvj0';
export const CONFIG_ROLE_COMPANY_LOADING = '8EDsxTl8z4';

/*ROLE PROJECT
*   1.List Role
*/
export const ROLE_PROJECT_INI = 'C0LtLYBt9n';
export const ROLE_PROJECT_SUCCESS = 'dcj8K4CHsV';
export const ROLE_PROJECT_LOADING = '1NYWfyLcxW';
export const ROLE_PROJECT_DETAIL = '33d4hTixRE';
/*
*   2. List config
*/
export const CONFIG_ROLE_PROJECT_INI = 'cOehZHmyIN';
export const CONFIG_ROLE_PROJECT_SUCCESS = 'F7TUMQAj5A';
export const CONFIG_ROLE_PROJECT_LOADING = 'zH3U7d262F';

/*project-status*/
export const PROJECT_STATUS_INI = 'lmN1BTMeii';
export const PROJECT_STATUS_SUCCESS = 'AlEBWlAW1v';
export const PROJECT_STATUS_LOADING = 'duaOYXkEQV';

/*project-detail*/
export const PROJECT_DETAIL_INI = 'ICILKBHJqA';
export const PROJECT_DETAIL_SUCCESS = 'iVYJ35B3ij';
export const PROJECT_DETAIL_LOADING = 'nImgfpGBFP';

/*user-company*/
export const USER_COMPANY_DETAIL_INI = '6Hr3JbeS1D';
export const USER_COMPANY_DETAIL_SUCCESS = '1ZseA2SeZ6';
export const USER_COMPANY_PROJECT_SUCCESS = 'FU8B343rOO';
export const USER_COMPANY_DETAIL_LOADING = '5pw14ZgJ5T';

/*survey-type*/
export const SURVEY_TYPE_INI = 'A8Nu12d8hv';
export const SURVEY_TYPE_SUCCESS = 'irCydbAZQH';
export const SURVEY_TYPE_LOADING = 'La0bGoV2Nc';

/*survey-detail*/
export const SURVEY_DETAIL_INI = 'oItnEJQ43F';
export const SURVEY_DETAIL_SUCCESS = 'nZck0pBqUD';
export const SURVEY_DETAIL_LOADING = '1jNDzroRYt';

/*user-role company*/
export const USER_ROLE_COMPANY_INI = 'h63Y3IbM16';
export const USER_ROLE_COMPANY_SUCCESS = 's4Ci0aQUHe';
export const USER_ROLE_COMPANY_LOADING = 'Jez72kEXOs';

/*user project*/
export const USER_PROJECT_INI = 'PdIcmbJGji';
export const USER_PROJECT_SUCCESS = 'bLe4stlnCD';
export const USER_PROJECT_LOADING = 'hUnkZcG099';

/*user-role project*/
export const USER_ROLE_PROJECT_INI = 'yUUh6TPuy4';
export const USER_ROLE_PROJECT_SUCCESS = 'VHmDLULT4m';
export const USER_ROLE_PROJECT_OPTIONS_SUCCESS = 'NGHEnWke15';
export const USER_ROLE_PROJECT_LOADING = 'lMcgYQgrLq';

/*column-type*/
export const COLUMN_TYPE_INI = 'BLe3Hh1m1F';
export const COLUMN_TYPE_SUCCESS = 'PxBsp5b1W4';
export const COLUMN_TYPE_LOADING = 'Xvfx0N6wwG';

/*config-column*/
export const CONFIG_COLUMN_INI = 'odAnZjtS48';
export const CONFIG_COLUMN_SUCCESS = 'KbfgiFNj2V';
export const CONFIG_COLUMN_LOADING = 'd21hFCU7MF';

/*store*/
export const STORE_INI = 'yVwdCzZojM';
export const STORE_SUCCESS = 'c4Wm9ZHjYy';
export const STORE_EXTRA_COLUMN_SUCCESS = 'A6QAWpaN64';
export const STORE_LOADING = 'l0Kcieq5ZX';

/*company*/
export const COMPANY_INI = 'Psi2Lc050t';
export const COMPANY_SUCCESS = 'WYroqFoezq';
export const COMPANY_SUCCESS_OPTIONS = 'KBh2oVSa79';
export const COMPANY_LIST_OPTIONS = 'k3cTiOnD3R';
export const COMPANY_PRODUCT_OPTIONS = 'ZAHQUt9f2T';
export const COMPANY_LOADING = 'OPJztNFN9l';

/*screen*/
export const SCREEN_INI = 'eUJn0qpJek';
export const SCREEN_SUCCESS = '5iDCIPk7gd';
export const SCREEN_DROPDOWN_SUCCESS = 'pu0aKIx7Pw';
export const SCREEN_LOADING = 'AUBhbTAjgg';

/*input-screen*/
export const INPUT_SCREEN_INI = 'YtjGZ3taUx';
export const INPUT_SCREEN_SUCCESS = 'v9ECYRGC6U';
export const INPUT_SCREEN_SURVEY_SUCCESS = 'NOvlowtTv9';
export const INPUT_SCREEN_LOADING = 'ivSQnU0fWh';

/*survey-group*/
export const SURVEY_GROUP_INI = '13mCQEemNP';
export const SURVEY_GROUP_SUCCESS = '1NjnlkDZMl';
export const SURVEY_GROUP_OPTIONS_SUCCESS = '7MPn21vOkv';
export const SURVEY_GROUP_LOADING = '63eedJYGAw';

/*input-type*/
export const INPUT_TYPE_INI = 'm4OGHkSKp8';
export const INPUT_TYPE_SUCCESS = 'd8WPkxNpbG';
export const INPUT_TYPE_LOADING = '1zEdyIODzd';

/*data-table*/
export const DATA_TABLE_INI = 'OiVcRqlh5g';
export const DATA_TABLE_VALUES = 'HLBBw82fPk';
export const DATA_TABLE_EXTRA_COLUMN = 'JlMwAty2uv';
export const DATA_TABLE_LOADING = '3Hyy7eYlEv';

/*product*/
export const PRODUCT_INI = 'HfBWJNhrml';
export const PRODUCT_SUCCESS = '7tJeJ7OM1P';
export const PRODUCT_ALL_SUCCESS = 'FVH8D6EAet';
export const PRODUCT_LOADING = 'OzJhPOtOI3';
export const PRODUCT_OPTIONS_SUCCESS = 'SsVe8QVrxU';

/*product-project*/
export const PRODUCT_PROJECT_INI = '6xAu2V7n2f';
export const PRODUCT_PROJECT_SUCCESS = 'rb9XTQBBi3';
export const PRODUCT_PROJECT_LOADING = '6ORAYrp9v4';

/*input*/
export const INPUT_INI = 'VSDgCDBpQS';
export const INPUT_SUCCESS = '9Qd8wpicRq';
export const INPUT_LOADING = 'Et8tm0I1wy';

/*AI type*/
export const AI_TYPE_INI = 'yJdz33dhQ5';
export const AI_TYPE_SUCCESS = 'c5NnTSvgPe';
export const AI_TYPE_LOADING = 'wHgGWIhV75';

/*AI Input*/
export const AI_INPUT_INI = '1d4o7eO34O';
export const AI_INPUT_SUCCESS = 'Go8kfbOHUs';
export const AI_INPUT_LOADING = 'OqkH4lW4po';

/*AI Product*/
export const AI_PRODUCT_INI = 'PkTEEkRbtV';
export const AI_PRODUCT_SUCCESS = '0kISNsDs4R';
export const AI_PRODUCT_LOADING = 'IGBIfohc7e';

/*AI SURVEY CONFIG*/
export const AI_SURVEY_CONFIG_INI = 'WlLHzmAGKb';
export const AI_SURVEY_CONFIG_SUCCESS = 'lOLsCVYSaC';
export const AI_SURVEY_CONFIG_LOADING = 'clooXRxI4r';

/*SURVEY DATA DETAIL*/
export const SURVEY_DATA_DETAIL_INI = 'o9KnGtpn1y';
export const SURVEY_DATA_DETAIL_SUCCESS = '4oPgnYI8IM';
export const SURVEY_DATA_DETAIL_LOADING = 'e4Z8uSSZ7q';

/*product-group*/
export const PRODUCT_GROUP_INI = 'Hmu82SqDsu';
export const PRODUCT_GROUP_SUCCESS = 'FYCBpNHQA4';
export const PRODUCT_GROUP_LOADING = 'g9vZguxe21';
export const PRODUCT_GROUP_OPTIONS_SUCCESS = 'GshAGUfz52';

/*store-user*/
export const STORE_USER_INI = 'hS0a1HgK3p';
export const STORE_USER_SUCCESS = 'QAQ64tKE9X';
export const STORE_USER_LOADING = 'CZnofRdrqp';


/*NOTIFICATION */
export const NOTIFICATION_INI = 'hS0a1HgK3g';
export const NOTIFICATION_SUCCESS = 'QAQ64tKE9R';
export const NOTIFICATION_LOADING = 'CZnofRdrqK';

/**TEMPLATE */
export const TEMPLATE_INI = 'hSYT1HgK3g';
export const TEMPLATE_LOADING = 'HAQ34tKE9R';
export const TEMPLATE_SUCCESS ='QHI64tME9X'

/**EXPORT */
export const EXPORT_INI = 'hSYT1sgsK3g';
export const EXPORT_LOADING = 'HUU34tKE9R';
export const EXPORT_SUCCESS ='QOOO64tME9X'

/**CONTACT */
export const CONTACT_INIT = 'FhfHtX3L6T';
export const CONTACT_LOADING = '7qViWFOvGc';
export const CONTACT_SUCCESS ='JH1dJKpHU7'
export const CONTACT_OPTIONS_SUCCESS ='IaM1dRModE'

/**ABOUT */
export const ABOUT_INI = 'CObkYN4uXB';
export const ABOUT_LOADING = 'PBrouSophA';
export const ABOUT_SUCCESS ='BBMwTxSD29'

/*CONFIG SHOW INPUT */

export const CONFIG_INPUT_SHOW_INI = 'rSZbav6Emp';
export const CONFIG_INPUT_SHOW_LOADING = 'ICiVUu9Vrt';
export const CONFIG_INPUT_SHOW_SUCCESS ='CXQQqgxR3o';

export const WINDOWSIZE_INIT = 'XyiBO4bw02';
export const WINDOWSIZE_CHANGE = 'xMRGKyOtGn';

export const ACCOUNT_INIT = 'aEA5X3bYrK';
export const ACCOUNT_CHANGE = 'cbF02zBcSE';

/**PERMISSION */

export const PERMISSION_INI = '4YFn46PRRe';
export const PERMISSION_LOADING = 'lLt5Zp4v2O';
export const PERMISSION_SUCCESS_COMPANY ='NrEbximVfq';
export const PERMISSION_SUCCESS_PROJECT ='ho0ubWSvPS';

/**QRCODE */

export const QRCODE_INI = '4YFn88PRRe';
export const QRCODE_LOADING = 'lHH5Zp4v2O';
export const QRCODE_SUCCESS ='NrHbximVfq';

/**DISTRICT */

export const ADDRESS_LOADING = 'QxTrHptXSp';
// export const DISTRICT_LOADING = '2PJo6z2Q2N';
export const DISTRICT_SUCCESS ='vtU2tV3JeW';

/**PROVINCE */

// export const PROVINCE_INI = '9aUAIVmFxt';
// export const PROVINCE_LOADING = 'fQMk7anhks';
export const PROVINCE_SUCCESS ='VvTH0YhOjD';

export const OVERVIEW_PROJECT_INIT = 'O6Q4WBCO2N';
export const OVERVIEW_PROJECT_LOADING = 'aCzXdHy4Va';
export const OVERVIEW_PROJECT_SUCCESS ='rjgBEIYtsF';

export const RUN_REFRESH_TOKEN ='rj7BEIYtsF';
export const STOP_REFESH_TOKEN ='rj8BEIYtsF';

