import * as types from '@/redux/contains/types';

const initialState = {
    detail: null,
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.EXPORT_INI:
            return {
                ...state
            };
        case types.EXPORT_SUCCESS:
        return {
            ...state,
            detail: action.detail
        }
        case types.EXPORT_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;