import * as types from '@/redux/contains/types';

const initialState = {
    severity: null,
    summary: null,
    detail: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ERROR_INI:
            return {
                severity: null,
                summary: null,
                detail: null
            };
        case types.ERROR_SHOW:
            return {
                severity: action.severity,
                summary: action.summary,
                detail: action.detail
            }
        default:
            return state;
    }
}

export default reducer;