const REACT_APP_PAGE_SIZE = process.env.NEXT_PUBLIC_REACT_APP_PAGE_SIZE;
const REACT_APP_MAX_WIDTH = process.env.NEXT_PUBLIC_REACT_APP_MAX_WIDTH;
const REACT_APP_MAX_HEIGHT = process.env.NEXT_PUBLIC_REACT_APP_MAX_HEIGHT;
const REACT_APP_SECRET_KEY = process.env.NEXT_PUBLIC_REACT_APP_SECRET_KEY;
const REACT_APP_SECRET_KEY_CLIENT = process.env.NEXT_PUBLIC_REACT_APP_SECRET_KEY_CLIENT;
const REACT_APP_JWT = process.env.NEXT_PUBLIC_REACT_APP_JWT;
const REACT_APP_JWT_REFESH = process.env.NEXT_PUBLIC_REACT_APP_JWT_REFESH;
const REACT_APP_PERMISSION_COMPANY = process.env.NEXT_PUBLIC_REACT_APP_PERMISSION_COMPANY;
const REACT_APP_PERMISSION_PROJECT = process.env.NEXT_PUBLIC_REACT_APP_PERMISSION_PROJECT;


export function GetURL() {
    var enviroment = 1;
    var baseUrlLink = 'http://localhost:3037';
    switch (enviroment) {
        case 0:
            baseUrlLink = 'http://localhost:3037';
            break;
        case 1:
            baseUrlLink = 'https://api-octopus.tengroup.com.vn';
            break;
        default:
            baseUrlLink = '';
            break;
    }
    return baseUrlLink;
}

const SERVICE_URI = GetURL();
// console.log(REACT_APP_SECRET_KEY_CLIENT)
export {
    REACT_APP_PAGE_SIZE,
    REACT_APP_MAX_WIDTH,
    REACT_APP_MAX_HEIGHT,
    REACT_APP_SECRET_KEY,
    REACT_APP_SECRET_KEY_CLIENT,
    REACT_APP_JWT,
    REACT_APP_JWT_REFESH,
    SERVICE_URI,
    REACT_APP_PERMISSION_COMPANY,
    REACT_APP_PERMISSION_PROJECT,
}