import { Fragment } from "react";
import Script from "next/script";

export default function InitFacebookSdk() {
    return (
        <Fragment>
            <Script
                id="facebook-init"
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                    __html: ` 
            window.fbAsyncInit = function() {
                // init the FB JS SDK
                FB.init({
                    appId      : '${process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID}', 
                    autoLogAppEvents : true,
                    xfbml            : true,
                    oauth            : true,
                    version          : 'v14.0'
                });
            }
    
            window.checkFacebookLoginState = function () {               // Called when a person is finished with the Login Button.
                FB.getLoginStatus(function(response) {   // See the onlogin handler
                  // console.log(response);
                  if(response.status==='unknown'){
                    FB.login(function(response) {
                      facebookCallback(response.authResponse)
                    });
                  }else{
                    facebookCallback(response.authResponse);
                  }
                });
            }
        `
                }}
            />
            <Script
                id="facebook-sdk"
                async
                defer
                strategy="lazyOnload"
                crossOrigin="anonymous"
                src={`https://connect.facebook.net/en_US/all.js`}
            />
        </Fragment>
    )
}