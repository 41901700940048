import * as types from '@/redux/contains/types';
import {initPermissionCompany,initPermissionProject} from '@/common/function'
const initialState = {
    permissionCompany: initPermissionCompany(),
    permissionProject: initPermissionProject(),
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PERMISSION_INI:
            return {
                ...state
            };
        case types.PERMISSION_SUCCESS_COMPANY:
            const {permissionCompany} = state
            permissionCompany[action.companyId] = action.detail
            return {
                ...state,
                permissionCompany: permissionCompany
            }
        case types.PERMISSION_SUCCESS_PROJECT:
            const {permissionProject} = state
            permissionProject[action.projectId] = action.detail
            return {
                ...state,
                permissionProject: permissionProject
            }
        case types.PERMISSION_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;