import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger'
import { useMemo } from 'react';
import error from './error';
import auth from './auth';
import companydetail from './company';
import rolecompany from './config/role/company';
import roleproject from './config/role/project';
import projectstatus from './project/status';
import project from './project';
import userproject from './project/user';
import usercompany from './company/user';
import userrolecompany from './company/user-role';
import userroleproject from './project/user/role';
import storecolumntype from './config/store/column-type';
import surveytype from './survey/type';
import survey from './survey';
import surveydatadetail from './survey/data';
import storedetail from './store';
import screendetail from './screen';
import configstorecolumn from './config/store/column';
import inputscreen from './screen/input';
import surveygroup from './survey/group';
import inputtype from './input/type';
import input from './input';
import datatable from './data-table/index';
import product from './product';
import productgroup from './product/group';
import productproject from './product/project';
import aitype from './ai/type';
import aiinput from './ai/input';
import aiproduct from './ai/product';
import aisurveyconfig from './ai/survey-config';
import notification from './notification/index';
import template from './template/index';
import qrcode from './qrcode/index';
import contact from './contact';
import about from './about';
import configInputShow from './survey/configInputShow';;
import dimensions from './dimensions';
import userlogin from './account';
import permission from './permission';
import address from './address'
import overviewproject from './project/overview'
import exportData from './export/index'
import statusExpToken from './statusExpToken'
const reducers = combineReducers({
  auth,
  error,
  rolecompany,
  roleproject,
  projectstatus,
  project,
  userproject,
  usercompany,
  userrolecompany,
  userroleproject,
  surveydatadetail,
  surveytype,
  survey,
  storecolumntype,
  configstorecolumn,
  storedetail,
  companydetail,
  screendetail,
  inputscreen,
  surveygroup,
  inputtype,
  input,
  datatable,
  product,
  productproject,
  productgroup,
  aitype,
  aiinput,
  aiproduct,
  aisurveyconfig,
  notification,
  template,
  contact,
  about,
  configInputShow,
  dimensions,
  userlogin,
  permission,
  address,
  overviewproject,
  exportData,
  statusExpToken,
  qrcode
});

let store

function initStore(initialState) {
  return createStore(
    reducers,
    initialState,
    // composeWithDevTools(applyMiddleware(thunkMiddleware, logger))
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  )
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export var currentStore;
export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  currentStore = store;
  return store;
}

