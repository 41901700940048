import * as types from '@/redux/contains/types';

const initialState = {
    store: null,
    listSurvey: [],
    listFile: [],
    listFileAI: [],
    listproducts: [],
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SURVEY_DATA_DETAIL_INI:
            return {
                ...state
            };
        case types.SURVEY_DATA_DETAIL_SUCCESS:
            return {
                ...state,
                store: action.store,
                listSurvey: action.listSurvey,
                listFile: action.listFile,
                listFileAI: action.listFileAI,
                listProduct: action.listProduct
            }
        case types.SURVEY_DATA_DETAIL_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;