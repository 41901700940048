import "../public/css/octopus.scss";
import "../public/css/octopus-v2.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "nprogress/nprogress.css";
import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "video.js/dist/video-js.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
// import "rc-color-picker/assets/index.css";
import "ka-table/style.css";

import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";
import Router from "next/router";
import { Fragment, useEffect } from "react";
import { Provider } from "react-redux";
import { useStore } from "../redux/reducers/index";
import NProgress from "nprogress";
import { useRouter } from "next/router";
import { ThemeProvider } from "styled-components";
import InitFacebookSdk from "@module/facebook";

// import * as actions from '@/redux/actions/index';

NProgress.configure({
  minimum: 0.3,
  easing: "ease",
  speed: 300,
  showSpinner: true,
});

const theme = {
  colors: {
    primary: "#0070f3",
  },
};

export default function App({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const start = (e) => {
      NProgress.start();
      // console.log(e);
    };
    const end = (e) => {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        NProgress.done();
        // console.log(e);
      }, 300);
    };

    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  useEffect(() => {
    const updateInfo = localStorage.getItem("require-update");
    if (updateInfo) {
      router.push("/update-info");
    }
  }, []);

  const store = useStore(pageProps.initialReduxState);

  const getLayout = Component.getLayout || ((page) => page);

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
  
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          {getLayout(<Component {...pageProps} />)}
        </Provider>
      </ThemeProvider>
      <InitFacebookSdk />
    </Fragment>
  );
}
