import * as types from '@/redux/contains/types';

const initialState = {
    list: [],
    detail: null,
    config: [],
    loadingList: false,
    loadingConfig: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ROLE_COMPANY_INI:
            return {
                ...state,
                list: [],
                loadingList: false
            };
        case types.CONFIG_ROLE_COMPANY_INI:
            return {
                ...state,
                config: [],
                loadingConfig: false
            };
        case types.ROLE_COMPANY_SUCCESS:
            return {
                ...state,
                list: action.list
            }
        case types.CONFIG_ROLE_COMPANY_SUCCESS:
            return {
                ...state,
                config: action.config
            }
        case types.ROLE_COMPANY_LOADING:
            return {
                ...state,
                loadingList: action.loadingList,
            }
        case types.CONFIG_ROLE_COMPANY_LOADING:
            return {
                ...state,
                loadingConfig: action.loadingConfig,
            }
        case types.ROLE_COMPANY_DETAIL:
            return {
                ...state,
                detail: action.detail
            }
        default:
            return {
                ...state
            };
    }
}

export default reducer;