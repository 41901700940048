import * as types from '@/redux/contains/types';

const initialState = {
    options: [],
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AI_PRODUCT_INI:
            return {
                ...state
            };
        case types.AI_PRODUCT_SUCCESS:
            return {
                ...state,
                options: action.options
            }
        case types.AI_PRODUCT_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;