import * as types from '@/redux/contains/types';

const initialState = {
    detail: null,
    options: [],
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CONTACT_INIT:
            return {
                ...state
            };
        case types.CONTACT_SUCCESS:
            return {
                ...state,
                detail: action.detail
            }
        case types.CONTACT_OPTIONS_SUCCESS:
            return {
                ...state,
                options: action.options
            }
        case types.CONTACT_LOADING:
            return {
                ...state, 
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;