import * as types from '@/redux/contains/types';

const initialState = {
    detail: null,
    history: [],
    options: [],
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PRODUCT_INI:
            return {
                ...state
            };
        case types.PRODUCT_SUCCESS:
            return {
                ...state,
                detail: action.detail
            }
        // case types.PRODUCT_ALL_SUCCESS:
        //     return {
        //         ...state,
        //         detail: action.detail,
        //         history: action.history
        //     }
        case types.PRODUCT_OPTIONS_SUCCESS:
            return {
                ...state,
                options: action.options
            }
        case types.PRODUCT_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;