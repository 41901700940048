import * as types from '@/redux/contains/types';

const initialState = {
    detail: null,
    loading: false,
    options: [],
    product: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.COMPANY_INI:
            return {
                ...state
            };
        case types.COMPANY_SUCCESS_OPTIONS:
            return {
                ...state,
                options: action.options
            }
        case types.COMPANY_PRODUCT_OPTIONS:
            return {
                ...state,
                product: action.product
            }
        case types.COMPANY_SUCCESS:
            return {
                ...state,
                detail: action.detail
            }
        case types.COMPANY_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;