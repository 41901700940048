import * as types from '@/redux/contains/types';
import {
    deCryptToken,
    deCryptRefreshToken
} from '@/common/Crypto';

const initialState = {
    profile: null,
    loading: false,
    token: deCryptToken(),
    refresh:  deCryptRefreshToken(),
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_INI:
            return {   
                token: deCryptToken(),
                refresh:  deCryptRefreshToken(),
                profile: null,
                loading: false
            };
        case types.AUTH_SUCCESS:
            return {
                ...state,
                profile: action.profile,
                token: action.token,
                refresh:action.refresh
            }
        case types.AUTH_RELOAD_TOKEN:
            return {
                ...state,
                token: action.token
            }
        case types.AUTH_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;