import jwt from 'jsonwebtoken'
import {
    REACT_APP_SECRET_KEY_CLIENT,
    REACT_APP_JWT,
    REACT_APP_SECRET_KEY,
    REACT_APP_JWT_REFESH
} from '@/common/config';
// const REACT_APP_SECRET_KEY_CLIENT = process.env.NEXT_PUBLIC_REACT_APP_SECRET_KEY_CLIENT;

import CryptoJS from "crypto-js";

export function enCryptData(decipherText) {

    var ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(decipherText)), REACT_APP_SECRET_KEY).toString();

    return ciphertext;
}


export function deCryptData(cipherText) {
    var bytes = CryptoJS.AES.decrypt(cipherText, REACT_APP_SECRET_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}

export function enCryptDataClient(decipherText) {
 //   console.log(decipherText)
  //  console.log(REACT_APP_SECRET_KEY_CLIENT)
    try {
        var ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(decipherText)), REACT_APP_SECRET_KEY_CLIENT).toString();
        return ciphertext;
    } catch (error) {
        console.log(error)
        return null
    }

}

export function deCryptDataClient(cipherText) {
    try {
        var bytes = CryptoJS.AES.decrypt(cipherText, REACT_APP_SECRET_KEY_CLIENT);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } 
    catch (error) {
        console.log(error)
        return null
    }

 
}

export function GetProfile() {
    try {
        let token = deCryptToken()
        if (token) {
            let decoded = jwt.decode(token);
            let obj = deCryptData(decoded.data);
            return obj;
        }
        return null;

    } catch (error) {
        return null;
    }
    

}


export function GetProfileString(token) {
    try {
        let decoded = jwt.decode(token);
        let obj = deCryptData(decoded.data);
        return obj;
    } catch (error) {
        return null;
    }
   
}

export function GetJWT() {
    let jwt = REACT_APP_JWT;;
    let browserToken = localStorage.getItem(jwt);
    return browserToken;
}

export function deCryptToken() {
    if (typeof window !== "undefined") {
        let browserToken = localStorage.getItem(REACT_APP_JWT);
        // console.log(localStorage);
        if (browserToken) {
            return deCryptDataClient(browserToken)
        }
        return null
    } else {
        return null
    }


}

export function deCryptRefreshToken() {
    if (typeof window !== "undefined") {
        let browserToken = localStorage.getItem(REACT_APP_JWT_REFESH);

        if (browserToken) {

            return deCryptDataClient(browserToken)
        }
        return null
    } else {

        return null
    }


}