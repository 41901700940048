import * as types from '@/redux/contains/types';

const initialState = {
    listAllDistrict: [],
    listAllProvince: [],
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case types.ABOUT_INI:
        //     return {
        //         ...state
        //     };
        case types.DISTRICT_SUCCESS:
            return {
                ...state,
                listAllDistrict: action.listAllDistrict
            }
        case types.PROVINCE_SUCCESS:
            return {
                ...state,
                listAllProvince: action.listAllProvince,
            }
        case types.ADDRESS_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;