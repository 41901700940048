import * as types from '@/redux/contains/types';

const initialState = {
    value: [],
    extracolumns: [],
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DATA_TABLE_INI:
            return {
                ...state
            };
        case types.DATA_TABLE_VALUES:
            return {
                ...state,
                value: action.value
            }
        case types.DATA_TABLE_EXTRA_COLUMN:
            return {
                ...state,
                extracolumns: action.extracolumns
            }
        case types.DATA_TABLE_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state;
    }
}

export default reducer;