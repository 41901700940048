import * as types from '@/redux/contains/types';

const initialState = {
    account: null,
    message: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ACCOUNT_INIT:
            return {
                account: null,
                message: "",
            };
        case types.ACCOUNT_CHANGE:
            return {
                account: action.account,
                message: "",
            }
        default:
            return state;
    }
}

export default reducer;